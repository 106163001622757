import { useEffect, useState } from 'react'
import { useBreakpointValue } from '@chakra-ui/react'
import { ContentstackMegaMenu as ContentstackMegaMenuProps } from '@Types/contentstack'
import { MegaDrawer } from 'composable/components/mega-drawer/MegaDrawer'
import useContentstack, { Entry } from '../hooks/useContentstack'
import { MegaMenu } from '../mega-menu/MegaMenu'

export const MEGA_MENU_ITEM = 'mega_menu_item'
export const MEGA_MENU_ITEM_GROUP = 'mega_menu_item_group'
export const MEGA_MENU_COVER_CARD = 'component_cover_card'

// set a default list of items to display in the Mega Menu while fetching the actual content
// this is to prevent layout shift
const INITIAL_CONTENT_LIST = [
  { label: 'THE DXL FACTOR', href: '/static/wear-what-you-want' },
  { label: 'NEW', href: '/c/new?sort=newest' },
  { label: 'BRANDS', href: '/static/brands' },
  { label: 'TRENDING', href: '/static/trending' },
  { label: 'SHIRTS', href: '/c/shirts' },
  { label: 'PANTS + SHORTS', href: '/c/pants-shorts' },
  { label: 'OUTERWEAR', href: '/c/outerwear' },
  { label: 'ACTIVEWEAR', href: '/c/activewear' },
  { label: 'TEAMS', href: '/static/team-shop' },
  { label: 'SUIT SHOP', href: '/c/suiting' },
  { label: 'UNDERWEAR + LOUNGE', href: '/c/underwear-lounge' },
  { label: 'SHOES', href: '/c/shoes' },
  { label: 'ACCESSORIES', href: '/c/accessories' },
  { label: 'SALE', href: '/c/sale' },
]

export const ContentstackMegaMenu = ({ items }: ContentstackMegaMenuProps) => {
  const [contentList, setContentList] = useState<Entry[]>(INITIAL_CONTENT_LIST as Entry[])
  const { getEntryByUid } = useContentstack()

  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })

  useEffect(() => {
    getMegaMenuContent(items)
  }, [])

  const getMegaMenuContent = async (items) => {
    try {
      const itemsResults = items?.map((item) => getEntryByUid(MEGA_MENU_ITEM, item.uid))
      const results = await Promise.all(itemsResults)
      setContentList(results)
    } catch (err) {
      console.log('Error processing Mega Menu data', err)
    }
  }

  return isMobile ? (
    <MegaDrawer items={contentList} />
  ) : (
    <MegaMenu isLoading={!contentList.length} items={contentList} />
  )
}
